import React from "react";
import goldss from "../assets/images/aesthetic-wallpaper-with-gold-bars.png";
import golds1080 from "../assets/images/aesthetic-wallpaper-with-gold-bars-1080.png";
import goldsAd from "../assets/images/aesthetic-wallpaper-with-gold-bars-ad.png";
import dollars from "../assets/images/american-dollars-cash-money.png";
import smartphone from "../assets/images/selective-focus-shot-male-hand-holding-smartphone.png";
import "../styles/Home.scss";

import up from "../assets/icons/up.svg";
import down from "../assets/icons/down.svg";
import diagram from "../assets/icons/diagram.svg";
import darkDiagram from "../assets/icons/dark-diagram.svg";
import Converter from "../components/Converter";
import WorldClocks from "../components/WorldClocks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Helper function to format time to military (24-hour) format
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

// Helper function to format date as "DD MMM" without year
const formatDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });
};

// Helper function to calculate percentage difference
const calculatePercentageDifference = (currentPrice, previousPrice) => {
  if (previousPrice === 0 || !currentPrice || !previousPrice) return 0;
  return (((currentPrice - previousPrice) / previousPrice) * 100).toFixed(2);
};

const HomePage = ({
  currencies,
  cryptoCurrencies,
  tomanCurrencies,
  golds,
  coins,
  noCategories,
  remittances,
  announcements,
  tryCurrencies,
  metalsAndCommodities,
  otherCommodities,
}) => {
  const isToggledFromStore = useSelector((state) => state.isToggled);

  // Find the currency with the latest `updatedAt`
  const latestCurrency = currencies?.reduce((latest, item) => {
    return new Date(item.updatedAt) > new Date(latest.updatedAt)
      ? item
      : latest;
  }, currencies[0]);

  const navigate = useNavigate();

  const handleNavigation = (selectedOption) => {
    navigate("/exchange-rate", {
      state: { selected: selectedOption, golds: golds, currencies: currencies },
    });
  };

  return (
    <div className="home">
      <div className="overlap-wrapper">
        <div className="overlap">
          {announcements && (
            <div className="gradient-border10">
              <div className="announcement">{announcements?.content}</div>
            </div>
          )}
          <div className="board-imgs">
            <div className="gradient-border3">
              <div className="prices">
                <div className="list-title">
                  <div
                    className={`title-4 ${
                      isToggledFromStore === false ? "black-scroll" : ""
                    }`}
                  >
                    {/* title-5 with the latest updated date and time */}
                    <div
                      className="g"
                      id={`${!isToggledFromStore ? "dark-title" : ""}`}
                    >
                      <div className="title-5">
                        {formatDate(latestCurrency?.updatedAt)}{" "}
                        <span className="max-[500px]:hidden">-</span>
                      </div>
                      <div className="title-5">
                        {formatTime(latestCurrency?.updatedAt)}
                      </div>
                    </div>
                    <div className="g-2">
                      <div className="title-6">Buying</div>
                      <div className="title-6">Selling</div>
                      <div className="title-6">Difference</div>
                    </div>
                  </div>
                  <div
                    className={`list-2 overflow-y-scroll h-[24rem] ${
                      isToggledFromStore === false ? "black-scroll" : ""
                    }`}
                  >
                    {/* {Array.isArray(currencies) &&
                      currencies.map((item, index) => ( */}
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7">
                          {tomanCurrencies[0]?.name}{" "}
                          <span className=""> /IRR</span>
                          <div>Ist</div>
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(tomanCurrencies[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {tomanCurrencies[0]?.istanbulBuyRateChange.includes(
                            "-"
                          )
                            ? tomanCurrencies[0]?.istanbulBuyPrice <=
                              tomanCurrencies[0]?.istanbulBuyRateChange
                              ? tomanCurrencies[0]?.istanbulBuyPrice
                              : (
                                  tomanCurrencies[0]?.istanbulBuyPrice -
                                  parseFloat(
                                    tomanCurrencies[0]?.istanbulBuyRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                tomanCurrencies[0]?.istanbulBuyPrice +
                                parseFloat(
                                  tomanCurrencies[0]?.istanbulBuyRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {tomanCurrencies[0]?.istanbulSellRateChange.includes(
                            "-"
                          )
                            ? tomanCurrencies[0]?.istanbulSellPrice <=
                              tomanCurrencies[0]?.istanbulSellRateChange
                              ? tomanCurrencies[0]?.istanbulSellPrice
                              : (
                                  tomanCurrencies[0]?.istanbulSellPrice -
                                  parseFloat(
                                    tomanCurrencies[0]?.istanbulSellRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                tomanCurrencies[0]?.istanbulSellPrice +
                                parseFloat(
                                  tomanCurrencies[0]?.istanbulSellRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              tomanCurrencies[0]?.istanbulSellPrice >
                              tomanCurrencies[0]?.istanbulPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              tomanCurrencies[0]?.istanbulSellPrice >
                              tomanCurrencies[0]?.istanbulPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              tomanCurrencies[0]?.istanbulSellPrice >
                              tomanCurrencies[0]?.istanbulPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              tomanCurrencies[0]?.istanbulSellPrice,
                              tomanCurrencies[0]?.istanbulPreSellPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7">
                          {tomanCurrencies[0]?.name}{" "}
                          <span className=""> /IRR</span>
                          <div>Teh</div>
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(tomanCurrencies[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {tomanCurrencies[0]?.tehranBuyRateChange.includes("-")
                            ? tomanCurrencies[0]?.tehranBuyPrice <=
                              tomanCurrencies[0]?.tehranBuyRateChange
                              ? tomanCurrencies[0]?.tehranBuyPrice
                              : (
                                  tomanCurrencies[0]?.tehranBuyPrice -
                                  parseFloat(
                                    tomanCurrencies[0]?.tehranBuyRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                tomanCurrencies[0]?.tehranBuyPrice +
                                parseFloat(
                                  tomanCurrencies[0]?.tehranBuyRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {tomanCurrencies[0]?.tehranSellRateChange.includes(
                            "-"
                          )
                            ? tomanCurrencies[0]?.tehranSellPrice <=
                              tomanCurrencies[0]?.tehranSellRateChange
                              ? tomanCurrencies[0]?.tehranSellPrice
                              : (
                                  tomanCurrencies[0]?.tehranSellPrice -
                                  parseFloat(
                                    tomanCurrencies[0]?.tehranSellRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                tomanCurrencies[0]?.tehranSellPrice +
                                parseFloat(
                                  tomanCurrencies[0]?.tehranSellRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              tomanCurrencies[0]?.tehranSellPrice >
                              tomanCurrencies[0]?.tehranPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              tomanCurrencies[0]?.tehranSellPrice >
                              tomanCurrencies[0]?.tehranPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              tomanCurrencies[0]?.tehranSellPrice >
                              tomanCurrencies[0]?.tehranPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              tomanCurrencies[0]?.tehranSellPrice,
                              tomanCurrencies[0]?.tehranPreSellPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7 title-7-sp">
                          <span>
                            {tryCurrencies[0]?.name || currencies[0]?.name}{" "}
                          </span>
                          <span className=""> /TRY</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(tryCurrencies[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {currencies[0]?.TRYBuyRateChange.includes("-")
                            ? currencies[0]?.TRYBuyPrice <=
                              currencies[0]?.TRYBuyRateChange
                              ? currencies[0]?.TRYBuyPrice
                              : (
                                  currencies[0]?.TRYBuyPrice -
                                  parseFloat(
                                    currencies[0]?.TRYBuyRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                currencies[0]?.TRYBuyPrice +
                                parseFloat(
                                  currencies[0]?.TRYBuyRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {currencies[0]?.TRYSellRateChange.includes("-")
                            ? currencies[0]?.TRYSellPrice <=
                              currencies[0]?.TRYSellRateChange
                              ? currencies[0]?.TRYSellPrice
                              : (
                                  currencies[0]?.TRYSellPrice -
                                  parseFloat(
                                    currencies[0]?.TRYSellRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                currencies[0]?.TRYSellPrice +
                                parseFloat(
                                  currencies[0]?.TRYSellRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              currencies[0]?.change !== "0"
                                ? currencies[0]?.change.includes("-")
                                  ? down
                                  : up
                                : currencies[0]?.TRYSellPrice >
                                  currencies[0]?.TRYPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              currencies[0]?.TRYSellPrice >
                              currencies[0]?.TRYPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              currencies[0]?.change !== "0"
                                ? currencies[0]?.change.includes("-")
                                  ? "red-rgba"
                                  : "green-rgba"
                                : currencies[0]?.TRYSellPrice >
                                  currencies[0]?.TRYPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {currencies[0]?.change !== "0"
                              ? currencies[0]?.change
                              : calculatePercentageDifference(
                                  currencies[0]?.TRYSellPrice,
                                  currencies[0]?.TRYPreSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7 title-7-sp">
                          <span>{coins[0]?.name}</span>
                          <span className=""> /IRR</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(coins[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {coins[0]?.buyRateChange.includes("-")
                            ? coins[0]?.buyPrice <= coins[0]?.buyRateChange
                              ? coins[0]?.buyPrice
                              : (
                                  coins[0]?.buyPrice -
                                  parseFloat(
                                    coins[0]?.buyRateChange.replace("-", "")
                                  )
                                ).toLocaleString()
                            : (
                                coins[0]?.buyPrice +
                                parseFloat(
                                  coins[0]?.buyRateChange.replace("-", "")
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {coins[0]?.sellRateChange.includes("-")
                            ? coins[0]?.sellPrice <= coins[0]?.sellRateChange
                              ? coins[0]?.sellPrice
                              : (
                                  coins[0]?.sellPrice -
                                  parseFloat(
                                    coins[0]?.sellRateChange.replace("-", "")
                                  )
                                ).toLocaleString()
                            : (
                                coins[0]?.sellPrice +
                                parseFloat(
                                  coins[0]?.sellRateChange.replace("-", "")
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              coins[0]?.sellPrice > coins[0]?.preSellPrice
                                ? up
                                : down
                            }
                            alt={
                              coins[0]?.sellPrice > coins[0]?.preSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              coins[0]?.sellPrice > coins[0]?.preSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              coins[0]?.sellPrice,
                              coins[0]?.preSellPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7 title-7-sp">
                          <span>{golds[0]?.name || "Gold"} </span>
                          <span className=""> /USD</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(golds[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {golds[0]?.istanbulBuyRateChange.includes("-")
                            ? golds[0]?.istanbulBuyPrice <=
                              golds[0]?.istanbulBuyRateChange
                              ? golds[0]?.istanbulBuyPrice
                              : (
                                  golds[0]?.istanbulBuyPrice -
                                  parseFloat(
                                    golds[0]?.istanbulBuyRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                golds[0]?.istanbulBuyPrice +
                                parseFloat(
                                  golds[0]?.istanbulBuyRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {golds[0]?.istanbulSellRateChange.includes("-")
                            ? golds[0]?.istanbulSellPrice <=
                              golds[0]?.istanbulSellRateChange
                              ? golds[0]?.istanbulSellPrice
                              : (
                                  golds[0]?.istanbulSellPrice -
                                  parseFloat(
                                    golds[0]?.istanbulSellRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                golds[0]?.istanbulSellPrice +
                                parseFloat(
                                  golds[0]?.istanbulSellRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              golds[0]?.change !== "0"
                                ? golds[0]?.change.includes("-")
                                  ? down
                                  : up
                                : golds[0]?.istanbulSellPrice >
                                  golds[0]?.istanbulPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              golds[0]?.istanbulSellPrice >
                              golds[0]?.istanbulPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              golds[0]?.change !== "0"
                                ? golds[0]?.change.includes("-")
                                  ? "red-rgba"
                                  : "green-rgba"
                                : golds[0]?.istanbulSellPrice >
                                  golds[0]?.istanbulPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {golds[0]?.change !== "0"
                              ? golds[0]?.change
                              : calculatePercentageDifference(
                                  golds[0]?.istanbulSellPrice,
                                  golds[0]?.istanbulPreSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7 title-7-sp">
                          <span>{noCategories[0]?.name} </span>
                          <span className=""> /USD</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(otherCommodities[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {noCategories[0]?.buyRateChange.includes("-")
                            ? noCategories[0]?.buyPrice <=
                              noCategories[0]?.buyRateChange
                              ? noCategories[0]?.buyPrice
                              : (
                                  noCategories[0]?.buyPrice -
                                  parseFloat(
                                    noCategories[0]?.buyRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                noCategories[0]?.buyPrice +
                                parseFloat(
                                  noCategories[0]?.buyRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {noCategories[0]?.sellRateChange.includes("-")
                            ? noCategories[0]?.sellPrice <=
                              noCategories[0]?.sellRateChange
                              ? noCategories[0]?.sellPrice
                              : (
                                  noCategories[0]?.sellPrice -
                                  parseFloat(
                                    noCategories[0]?.sellRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                noCategories[0]?.sellPrice +
                                parseFloat(
                                  noCategories[0]?.sellRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              noCategories[0]?.change !== "0"
                                ? noCategories[0]?.change.includes("-")
                                  ? down
                                  : up
                                : noCategories[0]?.sellPrice >
                                  noCategories[0]?.preSellPrice
                                ? up
                                : down
                            }
                            alt={
                              noCategories[0]?.sellPrice >
                              noCategories[0]?.preSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              noCategories[0]?.change !== "0"
                                ? noCategories[0]?.change.includes("-")
                                  ? "red-rgba"
                                  : "green-rgba"
                                : noCategories[0]?.sellPrice >
                                  noCategories[0]?.preSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {noCategories[0]?.change !== "0"
                              ? noCategories[0]?.change
                              : calculatePercentageDifference(
                                  noCategories[0]?.sellPrice,
                                  noCategories[0]?.preSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7 title-7-sp">
                          <span>{cryptoCurrencies[0]?.name} </span>
                          <span className=""> /USD</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(cryptoCurrencies[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {cryptoCurrencies[0]?.buyRateChange.includes("-")
                            ? cryptoCurrencies[0]?.buyPrice <=
                              cryptoCurrencies[0]?.buyRateChange
                              ? cryptoCurrencies[0]?.buyPrice
                              : (
                                  cryptoCurrencies[0]?.buyPrice -
                                  parseFloat(
                                    cryptoCurrencies[0]?.buyRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                cryptoCurrencies[0]?.buyPrice +
                                parseFloat(
                                  cryptoCurrencies[0]?.buyRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {cryptoCurrencies[0]?.buyRateChange.includes("-")
                            ? cryptoCurrencies[0]?.buyPrice <=
                              cryptoCurrencies[0]?.buyRateChange
                              ? cryptoCurrencies[0]?.buyPrice
                              : (
                                  cryptoCurrencies[0]?.buyPrice -
                                  parseFloat(
                                    cryptoCurrencies[0]?.buyRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                cryptoCurrencies[0]?.buyPrice +
                                parseFloat(
                                  cryptoCurrencies[0]?.buyRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              cryptoCurrencies[0]?.change !== "0"
                                ? cryptoCurrencies[0]?.change.includes("-")
                                  ? down
                                  : up
                                : cryptoCurrencies[0]?.sellPrice >
                                  cryptoCurrencies[0]?.preSellPrice
                                ? up
                                : down
                            }
                            alt={
                              cryptoCurrencies[0]?.sellPrice >
                              cryptoCurrencies[0]?.preSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              cryptoCurrencies[0]?.change !== "0"
                                ? cryptoCurrencies[0]?.change.includes("-")
                                  ? "red-rgba"
                                  : "green-rgba"
                                : cryptoCurrencies[0]?.sellPrice >
                                  cryptoCurrencies[0]?.preSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {cryptoCurrencies[0]?.change !== "0"
                              ? cryptoCurrencies[0]?.change
                              : calculatePercentageDifference(
                                  cryptoCurrencies[0]?.sellPrice,
                                  cryptoCurrencies[0]?.preSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7 title-7-sp">
                          {tomanCurrencies[5]?.name}{" "}
                          <span className=""> /IRR Ist</span>
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(tomanCurrencies[5].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {tomanCurrencies[5]?.istanbulBuyRateChange.includes(
                            "-"
                          )
                            ? tomanCurrencies[5]?.istanbulBuyPrice <=
                              tomanCurrencies[5]?.istanbulBuyRateChange
                              ? tomanCurrencies[5]?.istanbulBuyPrice
                              : (
                                  tomanCurrencies[5]?.istanbulBuyPrice -
                                  parseFloat(
                                    tomanCurrencies[5]?.istanbulBuyRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                tomanCurrencies[5]?.istanbulBuyPrice +
                                parseFloat(
                                  tomanCurrencies[5]?.istanbulBuyRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {tomanCurrencies[5]?.istanbulSellRateChange.includes(
                            "-"
                          )
                            ? tomanCurrencies[5]?.istanbulSellPrice <=
                              tomanCurrencies[5]?.istanbulSellRateChange
                              ? tomanCurrencies[5]?.istanbulSellPrice
                              : (
                                  tomanCurrencies[5]?.istanbulSellPrice -
                                  parseFloat(
                                    tomanCurrencies[5]?.istanbulSellRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                tomanCurrencies[5]?.istanbulSellPrice +
                                parseFloat(
                                  tomanCurrencies[5]?.istanbulSellRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              tomanCurrencies[5]?.istanbulSellPrice >
                              tomanCurrencies[5]?.istanbulPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              tomanCurrencies[5]?.istanbulSellPrice >
                              tomanCurrencies[5]?.istanbulPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              tomanCurrencies[5]?.istanbulSellPrice >
                              tomanCurrencies[5]?.istanbulPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              tomanCurrencies[5]?.istanbulSellPrice,
                              tomanCurrencies[5]?.istanbulPreSellPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7 title-7-sp">
                          {tomanCurrencies[5]?.name}{" "}
                          <span className=""> /IRR Teh</span>
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(tomanCurrencies[5].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {tomanCurrencies[5]?.tehranBuyRateChange.includes("-")
                            ? tomanCurrencies[5]?.tehranBuyPrice <=
                              tomanCurrencies[5]?.tehranBuyRateChange
                              ? tomanCurrencies[5]?.tehranBuyPrice
                              : (
                                  tomanCurrencies[5]?.tehranBuyPrice -
                                  parseFloat(
                                    tomanCurrencies[5]?.tehranBuyRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                tomanCurrencies[5]?.tehranBuyPrice +
                                parseFloat(
                                  tomanCurrencies[5]?.tehranBuyRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {tomanCurrencies[5]?.tehranSellRateChange.includes(
                            "-"
                          )
                            ? tomanCurrencies[5]?.tehranSellPrice <=
                              tomanCurrencies[5]?.tehranSellRateChange
                              ? tomanCurrencies[5]?.tehranSellPrice
                              : (
                                  tomanCurrencies[5]?.tehranSellPrice -
                                  parseFloat(
                                    tomanCurrencies[5]?.tehranSellRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()
                            : (
                                tomanCurrencies[5]?.tehranSellPrice +
                                parseFloat(
                                  tomanCurrencies[5]?.tehranSellRateChange.replace(
                                    "-",
                                    ""
                                  )
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              tomanCurrencies[5]?.tehranSellPrice >
                              tomanCurrencies[5]?.tehranPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              tomanCurrencies[5]?.tehranSellPrice >
                              tomanCurrencies[5]?.tehranPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              tomanCurrencies[5]?.tehranSellPrice >
                              tomanCurrencies[5]?.tehranPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              tomanCurrencies[5]?.tehranSellPrice,
                              tomanCurrencies[5]?.tehranPreSellPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>

                    {/* ))} */}
                  </div>
                </div>
                <div className="buttons">
                  <button
                    className="title-wrapper"
                    onClick={() => handleNavigation("Gold first")}
                  >
                    <div className="title-11">Gold rates</div>
                  </button>
                  <button
                    className="title-wrapper"
                    onClick={() => handleNavigation("Currency first")}
                  >
                    <div className="title-12">Currency rates</div>
                  </button>
                  <button
                    className="title-wrapper"
                    onClick={() => handleNavigation("Crypto currency first")}
                  >
                    <div className="title-13">Crypto rates</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="imgs">
              <img className="img-gold-ad" alt="Gold bars" src={goldsAd} />
              <div className="left">
                <img
                  className="img-gold-1080 hidden"
                  alt="Gold bars"
                  src={golds1080}
                />
                <img className="img-gold" alt="Gold bars" src={goldss} />
                <img
                  className="img-currency"
                  alt="American dollars"
                  src={dollars}
                />
              </div>
              <img className="img-crypto" alt="Smartphone" src={smartphone} />
            </div>
          </div>
          <Converter
            currencies={currencies}
            tomanCurrencies={tomanCurrencies}
            golds={golds}
            crypto={cryptoCurrencies}
          />
          <WorldClocks />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
