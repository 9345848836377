import React, { Fragment, useEffect, useState } from "react";
import "../styles/ExchangeRate.scss";

import up from "../assets/icons/up.svg";
import down from "../assets/icons/down.svg";
import diagram from "../assets/icons/diagram.svg";
import darkDiagram from "../assets/icons/dark-diagram.svg";
import dropdown from "../assets/icons/dropdown.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import WorldClocks from "../components/WorldClocks";

// Helper function to format time to military (24-hour) format
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

// Helper function to format date as "DD MMM" without year
const formatDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });
};

// Helper function to calculate percentage difference
const calculatePercentageDifference = (currentPrice, previousPrice) => {
  if (previousPrice === 0 || !currentPrice || !previousPrice) return 0;
  return (((currentPrice - previousPrice) / previousPrice) * 100).toFixed(2);
};

const ExchangeRatePage = ({
  currencies,
  cryptoCurrencies,
  tomanCurrencies,
  golds,
  coins,
  noCategories,
  remittances,
  tryCurrencies,
  metalsAndCommodities,
  otherCommodities,
}) => {
  
  const [selectedOption, setSelectedOption] = useState("Currency first");
  const [isOpen, setIsOpen] = useState(false);
  const [USDRates, setUSDRates] = useState({});
  const [TRYRates, setTRYRates] = useState({});

  const isToggledFromStore = useSelector((state) => state.isToggled);

  const location = useLocation();
  useEffect(() => {
    if (location.state?.selected) {
      setSelectedOption(location.state.selected);
    }
  }, [location.state]);

  const options = ["Gold first", "Currency first", "Crypto currency first"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Find the currency with the latest `updatedAt`
  const latestCurrency = cryptoCurrencies?.reduce((latest, item) => {
    return new Date(item?.updatedAt) > new Date(latest?.updatedAt)
      ? item
      : latest;
  }, currencies[0]);
  const latestTime = currencies?.reduce((latest, item) => {
    return new Date(item?.updatedAt) > new Date(latest?.updatedAt)
      ? item
      : latest;
  }, currencies[0]);

  // Fetch data from the API on component mount
  useEffect(() => {
    // const fetchUSDRates = async () => {
    //   try {
    //     const response = await fetch(
    //       "https://api.metalpriceapi.com/v1/latest?api_key=be59eb2982af3b77cd60353923939e90&base=USD&currencies=EUR,GBP,AED,CNY,BTC,ETH,USDT,BNB"
    //     );
    //     const data = await response.json();
    //     if (data.success) {
    //       setUSDRates(data.rates); // Save the rates from the API response
    //     }
    //   } catch (error) {
    //     console.error("Error fetching rates:", error);
    //   }
    // };
    // const fetchTRYRates = async () => {
    //   try {
    //     const response = await fetch(
    //       "https://api.metalpriceapi.com/v1/latest?api_key=be59eb2982af3b77cd60353923939e90&base=TRY&currencies=USD,EUR,GBP,AED,CNY,BTC,ETH,USDT,BNB"
    //     );
    //     const data = await response.json();
    //     if (data.success) {
    //       setTRYRates(data.rates); // Save the rates from the API response
    //     }
    //   } catch (error) {
    //     console.error("Error fetching rates:", error);
    //   }
    // };
    // fetchTRYRates();
    // fetchUSDRates();
  }, []);

  const renderCurrencyLiraTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestTime?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestTime?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Currency / TRY
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestTime?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestTime?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              {Array.isArray(currencies) &&
                Object.keys(tryCurrencies).length === 0 &&
                currencies?.map(
                  (item, index) => (
                    console.log(item),
                    (
                      <div className="item" key={`${item.name}-${index}`}>
                        <div className="g-3">
                          <div className="title-7">
                            {item.name}
                            <span className=""> /TRY</span>
                          </div>
                          {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                        </div>
                        <div className="g-2">
                          <div className="text">
                            {item?.TRYBuyRateChange.includes("-")
                              ? item.TRYBuyPrice <= item?.TRYBuyRateChange
                                ? item.TRYBuyPrice
                                : (
                                    item?.TRYBuyPrice -
                                    parseFloat(
                                      item?.TRYBuyRateChange.replace("-", "")
                                    )
                                  ).toLocaleString()
                              : (
                                  item?.TRYBuyPrice +
                                  parseFloat(
                                    item?.TRYBuyRateChange.replace("-", "")
                                  )
                                ).toLocaleString()}
                          </div>
                          <div className="text">
                            {item.TRYSellRateChange.includes("-")
                              ? item.TRYSellPrice <= item?.TRYSellRateChange
                                ? item.TRYSellPrice
                                : (
                                    item?.TRYSellPrice -
                                    parseFloat(
                                      item?.TRYSellRateChange.replace("-", "")
                                    )
                                  ).toLocaleString()
                              : (
                                  item?.TRYSellPrice +
                                  parseFloat(
                                    item?.TRYSellRateChange.replace("-", "")
                                  )
                                ).toLocaleString()}
                          </div>
                          <div className="text-icon">
                            <img
                              src={
                                item?.change !== "0"
                                  ? item?.change.includes("-")
                                    ? down
                                    : up
                                  : item.TRYSellPrice > item?.TRYPreSellPrice
                                  ? up
                                  : down
                              }
                              alt={
                                item.TRYSellPrice > item.TRYPreSellPrice
                                  ? "Uptrend"
                                  : "Downtrend"
                              }
                            />
                            <div
                              className={`title-9 ${
                                item?.change !== "0"
                                  ? item?.change.includes("-")
                                    ? "red-rgba"
                                    : "green-rgba"
                                  : item.TRYSellPrice > item.TRYPreSellPrice
                                  ? "green-rgba"
                                  : "red-rgba"
                              }`}
                            >
                              {item?.change !== "0"
                                ? item?.change
                                : calculatePercentageDifference(
                                    item.TRYSellPrice,
                                    item.TRYPreSellPrice
                                  )}
                              %
                            </div>
                          </div>
                        </div>
                        <img
                          className="ml-2"
                          src={!isToggledFromStore ? darkDiagram : diagram}
                          alt="Trend diagram"
                        />
                      </div>
                    )
                  )
                )}
              {Array.isArray(tryCurrencies) &&
                tryCurrencies?.map((item, index) => (
                  <div className="item" key={`${item?.name}-${index}`}>
                    <div className="g-3">
                      <div className="title-7">
                        {item?.name}
                        <span className=""> /TRY</span>
                      </div>
                      {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                    </div>
                    <div className="g-2">
                      <div className="text">
                        {TRYRates["TRY" + item?.symbol]
                          ?.toFixed(2)
                          .toLocaleString() || item?.buyPrice?.toLocaleString()}
                      </div>
                      <div className="text">
                        {item?.sellPrice?.toLocaleString()}
                      </div>
                      <div className="text-icon">
                        <img
                          src={item?.sellPrice > item?.preSellPrice ? up : down}
                          alt={
                            item?.sellPrice > item?.preSellPrice
                              ? "Uptrend"
                              : "Downtrend"
                          }
                        />
                        <div
                          className={`title-9 ${
                            item?.sellPrice > item?.preSellPrice
                              ? "green-rgba"
                              : "red-rgba"
                          }`}
                        >
                          {item?.change !== "0"
                            ? item?.change
                            : calculatePercentageDifference(
                                item?.sellPrice,
                                item?.preSellPrice
                              )}
                          %
                        </div>
                      </div>
                    </div>
                    <img
                      className="ml-2"
                      src={!isToggledFromStore ? darkDiagram : diagram}
                      alt="Trend diagram"
                    />
                  </div>
                )) &&
                currencies
                  ?.filter(
                    (item) => item.id !== 1 || item.id !== 2 || item.id !== 3
                  )
                  .map((item, index) => (
                    <div className="item" key={`${item.name}-${index}`}>
                      <div className="g-3">
                        <div className="title-7">
                          {item.name}
                          <span className=""> /TRY</span>
                        </div>
                        {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {TRYRates["TRY" + item.symbol]
                            ?.toFixed(2)
                            .toLocaleString() ||
                            item?.TRYBuyPrice?.toLocaleString()}
                        </div>
                        <div className="text">
                          {item.TRYSellPrice?.toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              item.TRYSellPrice > item?.TRYPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              item.TRYSellPrice > item.TRYPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              item.TRYSellPrice > item.TRYPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {item?.change !== "0"
                              ? item?.change
                              : calculatePercentageDifference(
                                  item.TRYSellPrice,
                                  item.TRYPreSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCurrencyDollarTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestTime?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestTime?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Currency / USD
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestTime?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestTime?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              {Array.isArray(currencies) &&
                currencies
                  .filter((item) => item.id !== 1)
                  .map((item, index) => (
                    <div className="item" key={`${item.name}-${index}`}>
                      <div className="g-3">
                        <div className="title-7">
                          {item.name}
                          <span className=""> /USD</span>
                        </div>
                        {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {item?.USDBuyRateChange.includes("-")
                            ? item.USDBuyPrice <= item?.USDBuyRateChange
                              ? item.USDBuyPrice
                              : (
                                  item?.USDBuyPrice -
                                  parseFloat(
                                    item?.USDBuyRateChange.replace("-", "")
                                  )
                                ).toLocaleString()
                            : (
                                item?.USDBuyPrice +
                                parseFloat(
                                  item?.USDBuyRateChange.replace("-", "")
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {item.USDSellRateChange.includes("-")
                            ? item.USDSellPrice <= item?.USDSellRateChange
                              ? item.USDSellPrice
                              : (
                                  item?.USDSellPrice -
                                  parseFloat(
                                    item?.USDSellRateChange.replace("-", "")
                                  )
                                ).toLocaleString()
                            : (
                                item?.USDSellPrice +
                                parseFloat(
                                  item?.USDSellRateChange.replace("-", "")
                                )
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? down
                                  : up
                                : item.USDSellPrice > item.USDPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              item.USDSellPrice > item.USDPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? "red-rgba"
                                  : "green-rgba"
                                : item.USDSellPrice > item.USDPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {item?.change !== "0"
                              ? item?.change
                              : calculatePercentageDifference(
                                  item.USDSellPrice,
                                  item.USDPreSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCurrencyDollarReverseTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestTime?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestTime?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  USD / Currency
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestTime?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestTime?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              {Array.isArray(currencies) &&
                currencies
                  .filter((item) => item.id !== 1)
                  .map((item, index) => (
                    <div className="item" key={`${item.name}-${index}`}>
                      <div className="g-3">
                        <div className="title-7">
                          <span className="">USD / </span>
                          {item.name}
                        </div>
                        {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {item?.USDBuyRateChange.includes("-")
                            ? item.USDBuyPrice <= item?.USDBuyRateChange
                              ? 1 / item.USDBuyPrice
                              : (
                                  1 /
                                  (item?.USDBuyPrice -
                                    parseFloat(
                                      item?.USDBuyRateChange.replace("-", "")
                                    ))
                                ).toLocaleString()
                            : (
                                1 /
                                (item?.USDBuyPrice +
                                  parseFloat(
                                    item?.USDBuyRateChange.replace("-", "")
                                  ))
                              ).toLocaleString()}
                        </div>
                        <div className="text">
                          {item.USDSellRateChange.includes("-")
                            ? item.USDSellPrice <= item?.USDSellRateChange
                              ? 1 / item.USDSellPrice
                              : (
                                  1 /
                                  (item?.USDSellPrice -
                                    parseFloat(
                                      item?.USDSellRateChange.replace("-", "")
                                    ))
                                ).toLocaleString()
                            : (
                                1 /
                                (item?.USDSellPrice +
                                  parseFloat(
                                    item?.USDSellRateChange.replace("-", "")
                                  ))
                              ).toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? down
                                  : up
                                : item.USDSellPrice < item.USDPreSellPrice
                                ? up
                                : down
                            }
                            alt={
                              item.USDSellPrice < item.USDPreSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? "red-rgba"
                                  : "green-rgba"
                                : item.USDSellPrice < item.USDPreSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {item?.change !== "0"
                              ? item?.change
                              : calculatePercentageDifference(
                                  item.USDSellPrice,
                                  item.USDPreSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCurrencyTomanIstanbulTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestTime?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestTime?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Istanbul Currency
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestTime?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestTime?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              <>
                {Array.isArray(tomanCurrencies) &&
                  tomanCurrencies
                    .filter((item) => item.id !== 6)
                    .map((item, index) => (
                      <div className="item" key={`${item.name}-${index}`}>
                        <div className="g-3">
                          <div className="title-7">
                            {item.name}
                            <span className=""> /IRR</span>
                          </div>
                          {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                        </div>
                        <div className="g-2">
                          <div className="text">
                            {item?.istanbulBuyRateChange.includes("-")
                              ? item.istanbulBuyPrice <=
                                item?.istanbulBuyRateChange
                                ? item.istanbulBuyPrice
                                : (
                                    item?.istanbulBuyPrice -
                                    parseFloat(
                                      item?.istanbulBuyRateChange.replace(
                                        "-",
                                        ""
                                      )
                                    )
                                  ).toLocaleString()
                              : (
                                  item?.istanbulBuyPrice +
                                  parseFloat(
                                    item?.istanbulBuyRateChange.replace("-", "")
                                  )
                                ).toLocaleString()}
                          </div>
                          <div className="text">
                            {item.istanbulSellRateChange.includes("-")
                              ? item.istanbulSellPrice <=
                                item?.istanbulSellRateChange
                                ? item.istanbulSellPrice
                                : (
                                    item?.istanbulSellPrice -
                                    parseFloat(
                                      item?.istanbulSellRateChange.replace(
                                        "-",
                                        ""
                                      )
                                    )
                                  ).toLocaleString()
                              : (
                                  item?.istanbulSellPrice +
                                  parseFloat(
                                    item?.istanbulSellRateChange.replace(
                                      "-",
                                      ""
                                    )
                                  )
                                ).toLocaleString()}
                          </div>
                          <div className="text-icon">
                            <img
                              src={
                                item.istanbulSellPrice >
                                item.istanbulPreSellPrice
                                  ? up
                                  : down
                              }
                              alt={
                                item.istanbulSellPrice >
                                item.istanbulPreSellPrice
                                  ? "Uptrend"
                                  : "Downtrend"
                              }
                            />
                            <div
                              className={`title-9 ${
                                item.istanbulSellPrice >
                                item.istanbulPreSellPrice
                                  ? "green-rgba"
                                  : "red-rgba"
                              }`}
                            >
                              {item?.change !== "0"
                                ? item?.change
                                : calculatePercentageDifference(
                                    item.istanbulSellPrice,
                                    item.istanbulPreSellPrice
                                  )}
                              %
                            </div>
                          </div>
                        </div>
                        <img
                          className="ml-2"
                          src={!isToggledFromStore ? darkDiagram : diagram}
                          alt="Trend diagram"
                        />
                      </div>
                    ))}

                <div
                  className="item"
                  key={`${tomanCurrencies[5]?.name} + "Istanbul"`}
                >
                  <div className="g-3">
                    <div className="title-7">
                      {tomanCurrencies[5]?.name}
                      <span className="">
                        {" "}
                        {tomanCurrencies[5]?.id === 6 ? "/IRR" : "/USD"}{" "}
                      </span>
                    </div>
                    {/* <div className="title-8">{formatTime(tomanCurrencies[5]?.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {USDRates["USD" + tomanCurrencies[5]?.symbol]?.toFixed(
                        2
                      ) ||
                        tomanCurrencies[5]?.istanbulBuyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {tomanCurrencies[5]?.istanbulSellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={
                          tomanCurrencies[5]?.istanbulSellPrice >
                          tomanCurrencies[5]?.istanbulPreSellPrice
                            ? up
                            : down
                        }
                        alt={
                          tomanCurrencies[5]?.istanbulSellPrice >
                          tomanCurrencies[5]?.istanbulPreSellPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          tomanCurrencies[5]?.istanbulSellPrice >
                          tomanCurrencies[5]?.istanbulPreSellPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          tomanCurrencies[5]?.istanbulSellPrice,
                          tomanCurrencies[5]?.istanbulPreSellPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCurrencyTomanTehranTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestTime?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestTime?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Tehran Currency
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestTime?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestTime?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              <>
                {Array.isArray(tomanCurrencies) &&
                  tomanCurrencies
                    .filter((item) => item.id !== 6)
                    .map((item, index) => (
                      <div className="item" key={`${item.name}-${index}`}>
                        <div className="g-3">
                          <div className="title-7">
                            {item.name}
                            <span className=""> /IRR</span>
                          </div>
                          {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                        </div>
                        <div className="g-2">
                          <div className="text">
                            {item?.tehranBuyRateChange.includes("-")
                              ? item.tehranBuyPrice <= item?.tehranBuyRateChange
                                ? item.tehranBuyPrice
                                : (
                                    item?.tehranBuyPrice -
                                    parseFloat(
                                      item?.tehranBuyRateChange.replace("-", "")
                                    )
                                  ).toLocaleString()
                              : (
                                  item?.tehranBuyPrice +
                                  parseFloat(
                                    item?.tehranBuyRateChange.replace("-", "")
                                  )
                                ).toLocaleString()}
                          </div>
                          <div className="text">
                            {item.tehranSellRateChange.includes("-")
                              ? item.tehranSellPrice <=
                                item?.tehranSellRateChange
                                ? item.tehranSellPrice
                                : (
                                    item?.tehranSellPrice -
                                    parseFloat(
                                      item?.tehranSellRateChange.replace(
                                        "-",
                                        ""
                                      )
                                    )
                                  ).toLocaleString()
                              : (
                                  item?.tehranSellPrice +
                                  parseFloat(
                                    item?.tehranSellRateChange.replace("-", "")
                                  )
                                ).toLocaleString()}
                          </div>
                          <div className="text-icon">
                            <img
                              src={
                                item.tehranSellPrice > item.tehranPreSellPrice
                                  ? up
                                  : down
                              }
                              alt={
                                item.tehranSellPrice > item.tehranPreSellPrice
                                  ? "Uptrend"
                                  : "Downtrend"
                              }
                            />
                            <div
                              className={`title-9 ${
                                item.tehranSellPrice > item.tehranPreSellPrice
                                  ? "green-rgba"
                                  : "red-rgba"
                              }`}
                            >
                              {calculatePercentageDifference(
                                item.tehranSellPrice,
                                item.tehranPreSellPrice
                              )}
                              %
                            </div>
                          </div>
                        </div>
                        <img
                          className="ml-2"
                          src={!isToggledFromStore ? darkDiagram : diagram}
                          alt="Trend diagram"
                        />
                      </div>
                    ))}

                <div
                  className="item"
                  key={`${tomanCurrencies[5]?.name + "tehran"}`}
                >
                  <div className="g-3">
                    <div className="title-7">
                      {tomanCurrencies[5]?.name}
                      <span className="">
                        {" "}
                        {tomanCurrencies[5]?.id === 6 ? "/IRR" : "/USD"}{" "}
                      </span>
                    </div>
                    {/* <div className="title-8">{formatTime(tomanCurrencies[5]?.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {USDRates["USD" + tomanCurrencies[5]?.symbol]?.toFixed(
                        2
                      ) || tomanCurrencies[5]?.tehranBuyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {tomanCurrencies[5]?.tehranSellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={
                          tomanCurrencies[5]?.tehranSellPrice >
                          tomanCurrencies[5]?.tehranPreSellPrice
                            ? up
                            : down
                        }
                        alt={
                          tomanCurrencies[5]?.tehranSellPrice >
                          tomanCurrencies[5]?.tehranPreSellPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          tomanCurrencies[5]?.tehranSellPrice >
                          tomanCurrencies[5]?.tehranPreSellPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {tomanCurrencies[5]?.change !== "0"
                          ? tomanCurrencies[5]?.change
                          : calculatePercentageDifference(
                              tomanCurrencies[5]?.tehranSellPrice,
                              tomanCurrencies[5]?.tehranPreSellPrice
                            )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderGoldIstanbulTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestTime?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestTime?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Istanbul Gold
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestTime?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestTime?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              {Array.isArray(golds) &&
                golds.map((item, index) => (
                  <div className="item" key={`${item.name}-${index}`}>
                    <div className="g-3">
                      <div className="title-7 title-7-sp">
                        <span>{item.name}</span>
                        <span className=""> /USD</span>
                      </div>
                      {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                    </div>
                    <div className="g-2">
                      <div className="text">
                        {item?.istanbulBuyRateChange.includes("-")
                          ? item.istanbulBuyPrice <= item?.istanbulBuyRateChange
                            ? item.istanbulBuyPrice
                            : (
                                item?.istanbulBuyPrice -
                                parseFloat(
                                  item?.istanbulBuyRateChange.replace("-", "")
                                )
                              ).toLocaleString()
                          : (
                              item?.istanbulBuyPrice +
                              parseFloat(
                                item?.istanbulBuyRateChange.replace("-", "")
                              )
                            ).toLocaleString()}
                      </div>
                      <div className="text">
                        {item.istanbulSellRateChange.includes("-")
                          ? item.istanbulSellPrice <=
                            item?.istanbulSellRateChange
                            ? item.istanbulSellPrice
                            : (
                                item?.istanbulSellPrice -
                                parseFloat(
                                  item?.istanbulSellRateChange.replace("-", "")
                                )
                              ).toLocaleString()
                          : (
                              item?.istanbulSellPrice +
                              parseFloat(
                                item?.istanbulSellRateChange.replace("-", "")
                              )
                            ).toLocaleString()}
                      </div>
                      <div className="text-icon">
                        <img
                          src={
                            item?.change !== "0"
                              ? item?.change.includes("-")
                                ? down
                                : up
                              : item.istanbulSellPrice >
                                item.istanbulPreSellPrice
                              ? up
                              : down
                          }
                          alt={
                            item.istanbulSellPrice > item.istanbulPreSellPrice
                              ? "Uptrend"
                              : "Downtrend"
                          }
                        />
                        <div
                          className={`title-9 ${
                            item?.change !== "0"
                              ? item?.change.includes("-")
                                ? "red-rgba"
                                : "green-rgba"
                              : item.istanbulSellPrice >
                                item.istanbulPreSellPrice
                              ? "green-rgba"
                              : "red-rgba"
                          }`}
                        >
                          {item?.change !== "0"
                            ? item?.change
                            : calculatePercentageDifference(
                                item.istanbulSellPrice,
                                item.istanbulPreSellPrice
                              )}
                          %
                        </div>
                      </div>
                    </div>
                    <img
                      className="ml-2"
                      src={!isToggledFromStore ? darkDiagram : diagram}
                      alt="Trend diagram"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderGoldTehranTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestTime?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestTime?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Tehran Gold
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestTime?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestTime?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              {Array.isArray(golds) &&
                golds.map((item, index) => (
                  <div className="item" key={`${item.name}-${index}`}>
                    <div className="g-3">
                      <div className="title-7">
                        {item.name}
                        <span className=""> /IRR</span>
                      </div>
                      {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                    </div>
                    <div className="g-2">
                      <div className="text">
                        {item.tehranBuyPrice.toLocaleString()}
                      </div>
                      <div className="text">
                        {item.tehranSellPrice.toLocaleString()}
                      </div>
                      <div className="text-icon">
                        <img
                          src={
                            item.tehranSellPrice > item.tehranPreSellPrice
                              ? up
                              : down
                          }
                          alt={
                            item.tehranSellPrice > item.tehranPreSellPrice
                              ? "Uptrend"
                              : "Downtrend"
                          }
                        />
                        <div
                          className={`title-9 ${
                            item.tehranSellPrice > item.tehranPreSellPrice
                              ? "green-rgba"
                              : "red-rgba"
                          }`}
                        >
                          {item?.change !== "0"
                            ? item?.change
                            : calculatePercentageDifference(
                                item.tehranSellPrice,
                                item.tehranPreSellPrice
                              )}
                          %
                        </div>
                      </div>
                    </div>
                    <img
                      className="ml-2"
                      src={!isToggledFromStore ? darkDiagram : diagram}
                      alt="Trend diagram"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCoinTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestTime?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestTime?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Tehran Gold
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestTime?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestTime?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              {Array.isArray(coins) &&
                coins.map((item, index) => (
                  <div className="item" key={`${item.name}-${index}`}>
                    <div className="g-3">
                      <div className="title-7 flex flex-col">
                        <span>{item.name}</span>
                        <span className=""> /IRR</span>
                      </div>
                      {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                    </div>
                    <div className="g-2">
                      <div className="text">
                        {item?.buyRateChange.includes("-")
                          ? item.buyPrice <= item?.buyRateChange
                            ? item.buyPrice
                            : (
                                item?.buyPrice -
                                parseFloat(item?.buyRateChange.replace("-", ""))
                              ).toLocaleString()
                          : (
                              item?.buyPrice +
                              parseFloat(item?.buyRateChange.replace("-", ""))
                            ).toLocaleString()}
                      </div>
                      <div className="text">
                        {item.sellRateChange.includes("-")
                          ? item.sellPrice <= item?.sellRateChange
                            ? item.sellPrice
                            : (
                                item?.sellPrice -
                                parseFloat(
                                  item?.sellRateChange.replace("-", "")
                                )
                              ).toLocaleString()
                          : (
                              item?.sellPrice +
                              parseFloat(item?.sellRateChange.replace("-", ""))
                            ).toLocaleString()}
                      </div>
                      <div className="text-icon">
                        <img
                          src={item.sellPrice > item.preSellPrice ? up : down}
                          alt={
                            item.sellPrice > item.preSellPrice
                              ? "Uptrend"
                              : "Downtrend"
                          }
                        />
                        <div
                          className={`title-9 ${
                            item.sellPrice > item.preSellPrice
                              ? "green-rgba"
                              : "red-rgba"
                          }`}
                        >
                          {calculatePercentageDifference(
                            item.sellPrice,
                            item.preSellPrice
                          )}
                          %
                        </div>
                      </div>
                    </div>
                    <img
                      className="ml-2"
                      src={!isToggledFromStore ? darkDiagram : diagram}
                      alt="Trend diagram"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCryptoTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestCurrency?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestCurrency?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4 !gap-[4.53rem] max-[500px]:!gap-[4rem]">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Crypto
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestCurrency?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestCurrency?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2 !gap-[6rem] max-[500px]:!gap-[4rem]">
                <div className="title-6">Price</div>
                {/* <div className="title-6">Selling</div> */}
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              <>
                {Array.isArray(cryptoCurrencies) &&
                  cryptoCurrencies?.map((item, index) => (
                    <div className="item" key={`${item.name}-${index}`}>
                      <div className="g-3">
                        <div className="title-7 title-7-sp ">
                          {item?.name}
                          <span className="">
                            {" "}
                            {item?.name === "TetherUS" ? "/IRR" : "/USD"}{" "}
                          </span>
                        </div>
                        {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                      </div>
                      <div className="g-2 !gap-[6rem] max-[500px]:!gap-[4rem]">
                        <div className="text">
                          {item?.buyRateChange.includes("-")
                            ? item.buyPrice <= item?.buyRateChange
                              ? item.buyPrice
                              : (
                                  item?.buyPrice -
                                  parseFloat(
                                    item?.buyRateChange.replace("-", "")
                                  )
                                ).toLocaleString()
                            : (
                                item?.buyPrice +
                                parseFloat(item?.buyRateChange.replace("-", ""))
                              ).toLocaleString()}
                        </div>
                        {/* <div className="text">{item.sellPrice}</div> */}
                        <div className="text-icon">
                          <img
                            src={
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? down
                                  : up
                                : item.sellPrice > item.preSellPrice
                                ? up
                                : down
                            }
                            alt={
                              item.sellPrice > item.preSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? "red-rgba"
                                  : "green-rgba"
                                : item.sellPrice > item.preSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {item?.change !== "0"
                              ? item?.change
                              : calculatePercentageDifference(
                                  item.sellPrice,
                                  item.preSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                  ))}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderNoCategoryTable = () => (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(latestTime?.updatedAt)} -</div>
        <div className="title-5">{formatTime(latestTime?.updatedAt)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Universal
                </div>
                <div className="g">
                  <div className="title-5">
                    {formatDate(latestTime?.updatedAt)} -
                  </div>
                  <div className="title-5">
                    {formatTime(latestTime?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="g-2">
                <div className="title-6">Buying</div>
                <div className="title-6">Selling</div>
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              {Array.isArray(noCategories) &&
                // Object.keys(otherCommodities).length === 0 &&
                noCategories.map((item, index) => (
                  <div className="item" key={`${item.name}-${index}`}>
                    <div className="g-3">
                      <div className="title-7 title-7-sp">
                        <span>{item?.name}</span>
                        <span className="">
                          {!(item?.name).includes("USD") && " /USD"}{" "}
                        </span>
                      </div>
                      {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                    </div>
                    <div className="g-2">
                      <div className="text">
                        {item?.buyRateChange.includes("-")
                          ? item.buyPrice <= item?.buyRateChange
                            ? item.buyPrice
                            : (
                                item?.buyPrice -
                                parseFloat(item?.buyRateChange.replace("-", ""))
                              ).toLocaleString()
                          : (
                              item?.buyPrice +
                              parseFloat(item?.buyRateChange.replace("-", ""))
                            ).toLocaleString()}
                      </div>
                      <div className="text">
                        {item.sellRateChange.includes("-")
                          ? item.sellPrice <= item?.sellRateChange
                            ? item.sellPrice
                            : (
                                item?.sellPrice -
                                parseFloat(
                                  item?.sellRateChange.replace("-", "")
                                )
                              ).toLocaleString()
                          : (
                              item?.sellPrice +
                              parseFloat(item?.sellRateChange.replace("-", ""))
                            ).toLocaleString()}
                      </div>
                      <div className="text-icon">
                        <img
                          src={
                            item?.change !== "0"
                              ? item?.change.includes("-")
                                ? down
                                : up
                              : item.sellPrice > item.preSellPrice
                              ? up
                              : down
                          }
                          alt={
                            item.sellPrice > item.preSellPrice
                              ? "Uptrend"
                              : "Downtrend"
                          }
                        />
                        <div
                          className={`title-9 ${
                            item?.change !== "0"
                              ? item?.change.includes("-")
                                ? "red-rgba"
                                : "green-rgba"
                              : item.sellPrice > item.preSellPrice
                              ? "green-rgba"
                              : "red-rgba"
                          }`}
                        >
                          {item?.change !== "0"
                            ? item?.change
                            : calculatePercentageDifference(
                                item.sellPrice,
                                item.preSellPrice
                              )}
                          %
                        </div>
                      </div>
                    </div>
                    <img
                      className="ml-2"
                      src={!isToggledFromStore ? darkDiagram : diagram}
                      alt="Trend diagram"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderRemittanceTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Remittance
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(remittances) &&
              remittances.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /USD</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {USDRates["USD" + item.symbol]?.toFixed(2) ||
                        item.buyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {item.sellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={
                          item?.change !== "0"
                            ? item?.change.includes("-")
                              ? down
                              : up
                            : item.sellPrice > item.preSellPrice
                            ? up
                            : down
                        }
                        alt={
                          item.sellPrice > item.preSellPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item?.change !== "0"
                            ? item?.change.includes("-")
                              ? "red-rgba"
                              : "green-rgba"
                            : item.sellPrice > item.preSellPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {item?.change !== "0"
                          ? item?.change
                          : calculatePercentageDifference(
                              item.sellPrice,
                              item.preSellPrice
                            )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="exchange-rate-page">
      <div className="custom-dropdown">
        <div className="gradient-border">
          <div
            className={`dropdown-header ${isOpen ? "open" : ""}`}
            onClick={toggleDropdown}
          >
            <img
              src={dropdown}
              alt="dropdown-background"
              className="dropdown-bg mr-[10px]"
            />
            <span>{selectedOption}</span>
          </div>
        </div>
        {isOpen && (
          <ul
            className={`dropdown-options ${
              !isToggledFromStore ? "dark-drop" : ""
            }`}
          >
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className="dropdown-option"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="exchange-rate-container">
        {selectedOption === "Gold first" && (
          <>
            {renderCoinTable()}
            {renderGoldIstanbulTable()}
            {/* {renderGoldTehranTable()} */}
            {renderCurrencyTomanIstanbulTable()}
            {renderCurrencyTomanTehranTable()}
            {renderCurrencyLiraTable()}
            {renderCurrencyDollarTable()}
            {renderCurrencyDollarReverseTable()}
            {renderNoCategoryTable()}
            {renderCryptoTable()}
            {/* {renderRemittanceTable()} */}
          </>
        )}
        {selectedOption === "Currency first" && (
          <>
            {renderCurrencyTomanIstanbulTable()}
            {renderCurrencyTomanTehranTable()}
            {renderCurrencyLiraTable()}
            {renderCurrencyDollarTable()}
            {renderCurrencyDollarReverseTable()}
            {renderCoinTable()}
            {renderGoldIstanbulTable()}
            {/* {renderGoldTehranTable()} */}
            {renderNoCategoryTable()}
            {renderCryptoTable()}
            {/* {renderRemittanceTable()} */}
          </>
        )}
        {selectedOption === "Crypto currency first" && (
          <>
            {renderCryptoTable()}
            {renderCurrencyTomanIstanbulTable()}
            {renderCurrencyTomanTehranTable()}
            {renderCurrencyLiraTable()}
            {renderCurrencyDollarTable()}
            {renderCurrencyDollarReverseTable()}
            {renderCoinTable()}
            {renderGoldIstanbulTable()}
            {renderNoCategoryTable()}
            {/* {renderGoldTehranTable()} */}
            {/* {renderRemittanceTable()} */}
          </>
        )}
      </div>
      <WorldClocks />
    </div>
  );
};

export default ExchangeRatePage;
